<template>
  <transition name="back-to-top-fade">
    <div 
    class="vue-back-to-top"  
    v-show="visible" 
    @click="backToTop">
    <slot>
      <div class="default">          
        <span class="material-icons back-to-top-icon" aria-hidden="true"> keyboard_arrow_up</span>
      </div>
    </slot>
  </div>
</transition>
</template>

<script>
  export default {
    name: 'BackToTop',
    props: {
      visibleoffset: {
        type: [String, Number],
        default: 600,
      },
      visibleoffsetbottom: {
        type: [String, Number],
        default: 0,
      },
      scrollFn: {
        type: Function,
        default: function (eventObject) {},
      }
    },
    data () {
      return {
        visible: false
      }
    },
    mounted () {
      window.smoothscroll = () => {
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
        if (currentScroll > 0) {
          window.requestAnimationFrame(window.smoothscroll)
          window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
        }
      }
      window.addEventListener('scroll', this.catchScroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.catchScroll)
    },
    methods: {
    /**
     * Catch window scroll event 
     * @return {void}
     */
     catchScroll () {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset)
      const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom)
      this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset
      this.scrollFn(this)
    },
    /**
     * The function who make the magics
     * @return {void}
     */
     backToTop () {
      window.smoothscroll()
      this.$emit('scrolled')
    }
  },
}
</script>
<style lang="scss" scoped>
  .back-to-top-fade-enter-active, 
  .back-to-top-fade-leave-active {
    transition: opacity .7s;
  }
  .back-to-top-fade-enter, 
  .back-to-top-fade-leave-to {
    opacity: 0;
  }

  .vue-back-to-top {
    cursor:pointer;
    position: fixed;
    z-index: 2000;
    bottom: 40px;
    right: 30px;

    @media (max-width: 991px) {
      bottom: 80px;
      right: 10px;
    }
  }

  .vue-back-to-top--is-footer {
    bottom: 50% !important;
    position: absolute;
    transform: translateY(50%);
  }

  .back-to-top-icon {
    font-size: 35px;
    box-shadow: 0px 4px 4px 0px #00000040;
    color: #3A3A3A;
    border-radius: 50%;
    padding: 5px;
    background-color: #FFF;
  }

  body[dir="rtl"] {
    .vue-back-to-top {
      right: auto;
      left: 30px;

      @media(max-width:991px){
        left: 10px;
      }
    }
  }
</style> 